jQuery(document).ready(function ($) {
    "use strict";

    // Add Active First Button (Slider Isotope)
    $('.sliderisotope_links button:first').addClass('active');

    // Add Popup Gallery
    $('.singleflex_gallery,.singleproperty_sliderfor').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    // Add Active Collapse
    $('.bitesizenews_accordion .card:first-child .btn-link').removeClass('collapsed');
    $('.bitesizenews_accordion .card:first-child .collapse').addClass('show');

    $('#loginform label').addClass('block');
    $('#loginform #user_login,#loginform #user_pass').addClass('form-control rounded-0');
    $('#loginform #wp-submit').addClass('btn btn-yellow rounded-0');

    // Clear Fix on Top Page
    $(window).scroll(function () {
        if ($(this).scrollTop() > 1) {
            $('header').removeClass("clearheaderbg");
        }
        else {
            $('header').addClass("clearheaderbg");
        }
    });

    // Stick Maps
    // $(".wppf_map_container").length && $(".wppf_map_container").stick_in_parent();

    // Sliders Gallary
    if ($('.sliderslickfor').length) {
        $('.sliderslickfor').slick({
            dots: false,
            arrows: true,
            fade: true,
            speed: 750,
            asNavFor: '.sliderslicknav'
        });
        $('.sliderslicknav').slick({
            dots: false,
            arrows: true,
            speed: 750,
            asNavFor: '.sliderslickfor',
            focusOnSelect: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: { slidesToShow: 4 }
                },
                {
                    breakpoint: 768,
                    settings: { slidesToShow: 4 }
                },
                {
                    breakpoint: 480,
                    settings: { slidesToShow: 3 }
                }
            ]
        });
    }

    if ($('.services-carousel').length) {
        $('.services-carousel').slick({
            slidesToShow: 3,
            infinite: true,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '13%',
            responsive: [
                {
                    breakpoint: 992,
                    settings: { slidesToShow: 2 }
                },
                {
                    breakpoint: 768,
                    settings: { slidesToShow: 2 }
                },
                {
                    breakpoint: 480,
                    settings: { slidesToShow: 1 }
                }
            ]
        });
    }

    class SiteHeader {

        constructor() {

            this.root = document.querySelector('.SiteHeader')
            if (!this.root) return;
            this.iconMenu = document.querySelector('.SiteHeader-iconMenu')
            this.iconClose = document.querySelector('.SiteHeaderOverlay-close button')
            this.headerOverlay = document.querySelector('.SiteHeaderOverlay')
            this.menuLinks = [...this.headerOverlay.querySelectorAll('.menu-item-has-children > a')]
            this.searchBox = document.querySelector('.SiteHeaderOverlay-search')
            this.overlayMenu = document.querySelector('.SiteHeaderOverlay-menu')
            this.visible = false;
            this._bindEvents()

        }

        _bindEvents() {

            [
                this.iconMenu,
                this.iconClose
            ].forEach(
                icon => icon.addEventListener('click', this._handleIconClick.bind(this))
            );

            this.menuLinks.forEach(
                link => link.addEventListener('click', this._handleNavClick.bind(this))
            )

            window.addEventListener(
                'scroll',
                _.debounce(this._toggleStateClass.bind(this), 40, { leading: true })
            );

            window.addEventListener('click', this._toggleOutsideOverlayClick.bind(this));

        }

        _handleNavClick(ev) {

            ev.preventDefault();
            $('.open').not($(ev.currentTarget).find('+ ul')).slideUp().removeClass('open');
            $(ev.currentTarget).find('+ ul').slideToggle().toggleClass('open');

        }

        _toggleStateClass() {

            const scrollDist = window.pageYOffset

            if (scrollDist > 200) {
                this.root.classList.add('SiteHeader--compact')
            } else {
                this.root.classList.remove('SiteHeader--compact')
            }

        }

        _handleIconClick(ev) {

            ev.preventDefault();
            const methodName = ev.currentTarget.dataset.action;
            this[methodName] && this[methodName]()

        }

        _showSearch() {
            this._toggleOverlay()
            this._toggleSearch()
        }

        _showMenu() {
            this._toggleOverlay()
            this._toggleMenu()
        }

        _closeOverlay() {
            this._toggleOverlay(true)
            this._toggleSearch(true)
            this._toggleMenu(true)
        }

        _toggleOverlay(hide = false) {
            this.headerOverlay.classList[hide ? 'remove' : 'add']('u-show');
            document.documentElement.style.overflowY = hide ? 'auto' : 'hidden';
            this.visible = !this.visible;
        }

        _toggleSearch(hide = false) {
            $(this.searchBox)[hide ? 'fadeOut' : 'fadeIn']()
        }

        _toggleMenu(hide = false) {
            if (hide) {
                $(this.overlayMenu)
                    .fadeOut();
            } else {
                $(this.overlayMenu)
                    .css('display', 'block')
                    .fadeIn();
            }
        }

        _toggleOutsideOverlayClick(ev) {
            if (!this.visible) {
                return true;
            }

            let container = document.querySelector('.u-show');
            let target = ev.target;

            if (this.visible
                && !(container.contains(target))
                && !$(target).hasClass('SiteHeader-iconMenu')) {
                this._toggleOverlay(true);
            }
        }

    }

    $('.wppf_form_small.wppf_sale.wppf_rent').show();

    var checkboxValues = JSON.parse(localStorage.getItem('checkboxValues')) || {};
    var $radio = $(".slidertop_search_radio00 :radio");
    var $checkboxes = $(".slidertop_search_radio00 :checkbox");

    $radio.on("change", function () {
        $radio.each(function () {
            checkboxValues[this.id] = this.checked;
        });
        localStorage.setItem("checkboxValues", JSON.stringify(checkboxValues));
    });

    $checkboxes.on("change", function () {
        $checkboxes.each(function () {
            checkboxValues[this.id] = this.checked;
        });
        localStorage.setItem("checkboxValues", JSON.stringify(checkboxValues));
    });

    if ($('.row.property-search-form.wppf_search').length) {
        if ((checkboxValues['for-sale'])) {
            $('#for-sale')[0].checked = true;
        }
        if ((checkboxValues['to-let'])) {
            $('#to-let')[0].checked = true;
        }
        if ((checkboxValues['exclude-uo-sstc'])) {
            $('#exclude-uo-sstc')[0].checked = true;
        }
    }

    $(window).on('load', function () {
        if ($('#for-sale').prop('checked') == true) {
            $('#exclude-uo-sstc').val('for sale');
            $('#checkbox-text').text('Exclude sold / under offer');
        }
        if ($('#to-let').prop('checked') == true) {
            $('#exclude-uo-sstc').val('to let');
            $('#checkbox-text').text('Exclude let agreed');
        }
    });

    $('#for-sale').on('change', function (ev) {
        if (ev.currentTarget.checked) {
            $('#exclude-uo-sstc').val('for sale');
            $('#checkbox-text').text('Exclude sold / under offer');
        }
    });

    $('#to-let').on('change', function (ev) {
        if (ev.currentTarget.checked) {
            $('#exclude-uo-sstc').val('to let');
            $('#checkbox-text').text('Exclude let agreed');
        }
    });


    new SiteHeader();

    $('.show-map').on('click', function (e) {

        e.preventDefault();
        $('#map-modal').modal();
    });

    $('.modal .modal-close').on('click', function () {
        $('#map-modal').modal('hide');
    })

    // Isotope
    var $team_container = $('.sliderisotope_items');
    if ($team_container.length) {
        $team_container.isotope({
            itemSelector: 'li',
            layoutMode: 'fitRows',
        });

        // Dropdown filtering
        $('#team-selector').on('change', function () {
            $team_container.isotope({
                filter: $(this).val()
            });
        });
    }

    // View full team button
    if ($('#btn--view-full-team').length) {
        let $btn = $('#btn--view-full-team');
        $btn.on('click', function () {
            let $team_id = $(this).data('teamId');
            localStorage.setItem('viewTeam', $team_id);
            window.location = meet_team_page_url.url;
        });
    }

    if ($('#team-selector').length) {
        var team_id = localStorage.getItem('viewTeam');

        if ('undefined' == team_id) return;

        var $team_options = $('#team-selector > option');

        $team_options.removeAttr('selected');
        $team_options.each(function () {
            if ($(this).data('teamId') == parseInt(team_id)) {
                $(this).attr('selected', 'selected');
            };
        });
        $('#team-selector').trigger('change');
        localStorage.removeItem('viewTeam');

    }
});

const allToTallest = (sel, bp = 200) => {
    let nodes = !Array.isArray(sel) ? [...document.querySelectorAll(sel)] : sel;
    if (!nodes.length) return;
    nodes.forEach(el => el.style.minHeight = 'auto')
    if (document.documentElement.clientWidth < bp) return;
    let nodeHeights = nodes.map(el => parseInt(el.offsetHeight))
    let tallest = Math.max(...nodeHeights)
    nodes.forEach(el => el.style.minHeight = tallest + 'px')

}

jQuery(window).on('load resize', function () {
    allToTallest('.boxpropertysearch_content');
});

// Fixed header on scroll up
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = jQuery('header').outerHeight();
jQuery(window).scroll(function (event) { didScroll = true; });
setInterval(function () {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);
function hasScrolled() {
    var st = jQuery(this).scrollTop();
    if (Math.abs(lastScrollTop - st) <= delta)
        return;
    if (st > lastScrollTop && st > navbarHeight) {
        jQuery('header').removeClass('nav-down').addClass('nav-up');
    } else {
        if (st + jQuery(window).height() < jQuery(document).height()) {
            jQuery('header').removeClass('nav-up').addClass('nav-down');
        }
    }
    lastScrollTop = st;
}
